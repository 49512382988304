<template>
  <v-card class="mb-1" elevation="0">
    <v-card-title>
      <strong class="titulo-header-page">Boletos Gerados</strong>
      <v-spacer></v-spacer>
    </v-card-title>
    <div>
      <v-row dense>
        <v-col cols="12" md="12">
          <div class="pl-5 pr-5">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-text-field color="#007744" label="Nome Titular:" v-model="nomeTitular" required outlined
                  dense></v-text-field>
              </v-col>

              <v-col cols="6" md="2">
                <v-text-field color="#007744" label="Data Inicial:" type="date" v-model="datas.dataIni" required
                  outlined dense></v-text-field>
              </v-col>

              <v-col cols="6" md="2">
                <v-text-field color="#007744" label="Data Final:" type="date" v-model="datas.dataFim" required outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <loading v-if="carregamento" />
    <found v-else-if="!carregamento && data.length == 0" />
    <v-simple-table v-else dense fixed-header height="400px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Titular</th>
            <th class="text-left">Descrição</th>
            <th class="text-left">Contato</th>
            <th class="text-center">Data de Vencimento</th>
            <th class="text-left">Situação</th>
            <th class="text-left">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in titulares" :key="index">
            <td class="text-left">{{ item.titular }}</td>
            <td class="text-left">{{ item.observacao }}</td>
            <td class="text-left">{{ item.contato }}</td>
            <td class="text-center">{{ item.data_vencimento }}</td>
            <td class="text-left">
              <v-chip v-if="item.situacao === 'Em Aberto'" color="grey" outlined x-small>
                {{ item.situacao }}
              </v-chip>
              <v-chip v-else color="green" outlined x-small>
                {{ item.situacao }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small dark icon v-bind="attrs" v-on="on" color="black">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="visualizar(item.identificador)" :disabled="item.situacao != 'Em Aberto'">
                    <v-list-item-title>Visualizar</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item @click="reenviar(item.contrato, item.data_vencimento)"
                    :disabled="item.situacao != 'Em Aberto'">
                    <v-list-item-title>Reenviar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="totais" v-if="data && data.length > 0">
      <ul>
        <li>Quantidade de cobranças: {{ titulares.length }}</li>
        <li>Cobranças em Aberto: {{ emAberto }}</li>
        <li>Cobranças Quitadas: {{ quitados }}</li>
      </ul>
    </div>
  </v-card>
</template>

<script>
import axios from "../../axios/service_private"; 
import global from '../../url.api.js';
import Swal from "sweetalert2";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data: () => ({
    data: [],
    titulares: [],
    titular: {
      nome: null,
      link: null,
      contato: null,
      data_criacao: null,
    },
    carregamento: false,
    datas: {
      dataIni: moment().startOf("month").format("yyyy-MM-DD"),
      dataFim: moment().endOf("month").format("yyyy-MM-DD")
    },
    quitados: 0,
    emAberto: 0,
    nomeTitular: "",
  }),
  methods: {
    async getTitulares() {
      this.carregamento = true;
      try {
        const titulares = await axios.get(`srv-sistema/api/v1/pet-primavera/relatorio/cobrancas`, {
          params: { ...this.datas },
        });

        if (titulares.data.status) {
          this.carregamento = false;
          this.data = titulares.data.cobrancas;
          this.titulares = this.data;
          this.situacoes(this.titulares);
        }
      } catch (error) {
        this.carregamento = false;
        this.$toast(error.response.data.message, {
          type: "error",
        });
      }
    },
    async reenviar(contrato, data_vencimento) {
      Swal.fire({
        title: `Reenvio!`,
        text: `Deseja reenviar cobrança?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, reenviar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(`srv-cobranca/reenvio`, {
              contrato,
              data_vencimento: moment(data_vencimento, 'DD/MM/YYYY').format("YYYY-MM-DD")
            });

            if (response.data.status) {
              this.$toast(response.data.message, {
                type: "success",
              });
            }
          } catch (error) {
            this.$toast("Não foi possivel reenviar boleto", {
              type: "error",
            });
          }
        }
      });
    },
    situacoes(array) {
      array.forEach((item) => {
        if (item.situacao === "Liquidado") {
          return this.quitados += 1;
        }
        return this.emAberto += 1;
      });
    },
    formatDate() {
      const dataInicial = moment(this.datas.dataIni).format("YYYY-MM-DD");
      const dataFinal = moment(this.datas.dataFim).format("YYYY-MM-DD");
      return [dataInicial, dataFinal];
    },
    visualizar(identificador) {
      window.open(`${global}srv-cobranca/associado-cobranca/${identificador}`, "_blank");
    },
    async setup() {
      this.$store.dispatch("verifyToken");
      await this.getTitulares();
    },
  },
  watch: {
    async "datas.dataIni"() {
      this.quitados = 0;
      this.emAberto = 0;
      await this.getTitulares();
    },
    async "datas.dataFim"() {
      this.quitados = 0;
      this.emAberto = 0;
      await this.getTitulares();
    },
    nomeTitular(novoValor) {
      this.titulares = this.data.filter((titular) =>
        titular.titular.toLowerCase().includes(novoValor.toLowerCase())
      );
    },
  },
  async mounted() {
    await this.setup();
  },
};
</script>

<style scoped>
.totais {
  padding: 20px 0px;
}

.totais ul {
  list-style: none;
}

.totais li {
  font-weight: bold;
  padding-top: 5px;
}
</style>
