<template>
  <div v-if="!carrementoModalDetlhar">
    <v-dialog v-model="dialogExtrato" max-width="1000px">
      <v-card>
        <v-card-title><b>Extrato pagamento</b> </v-card-title>
        <loading v-if="carregamentoExtrato" />
        <div v-else>
          <v-row dense class="filter pa-5">
            <v-col cols="12" md="6">
              <v-text-field
                class="input"
                color="#007744"
                label="Data inicial:"
                v-model="paramsExtrato.inicio"
                required
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="input"
                v-model="paramsExtrato.fim"
                @input="() => this.loadExtrato()"
                color="#007744"
                type="date"
                label="Data final:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div id="extrato">
            <div class="header">
              <div>
                <img src="img/logo.png" alt="logo" />
              </div>
              <div>
                <h1>Extrato de pagamento</h1>
                <ul>
                  <li>
                    Emitido por: <b>{{ this.user.usuario }}</b>
                  </li>
                  <li>
                    Emitido em: <b> {{ dataAtual() }}</b>
                  </li>
                  <li>
                    Titular:
                    <b> {{ titular.nome }}</b>
                  </li>
                  <li>
                    <b> {{ titular.descricao }} </b>
                  </li>
                  <li>
                    Parcela(s) paga(s): <b> {{ extrato.length }}</b>
                  </li>
                  <li>
                    Total:
                    <b>{{
                      formatPreco(calcularTotalParcelasPagas(extrato))
                    }}</b>
                  </li>
                </ul>
              </div>
            </div>

            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Parcela</th>
                    <th class="text-center">Valor parcela</th>
                    <th class="text-center">Valor desconto</th>
                    <th class="text-center">Valor adicional</th>
                    <th class="text-center">Valor total</th>
                    <th class="text-center">Valor pago</th>
                    <th class="text-center">Usuário</th>
                    <th class="text-center">Pago em</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in extrato" :key="index">
                    <td class="text-center">
                      {{ item.numero_parcela }}
                    </td>
                    <td class="text-center">
                      {{ formatPreco(item.valor_parcela) }}
                    </td>
                    <td class="text-center">
                      {{ formatPreco(item.valor_desconto) }}
                    </td>
                    <td class="text-center">
                      {{ formatPreco(item.valor_adicional) }}
                    </td>
                    <td class="text-center">
                      {{ formatPreco(item.valor_apagar) }}
                    </td>
                    <td class="text-center">
                      <v-chip
                        class="ma-2"
                        color="red"
                        small
                        text-color="white"
                        v-if="!item.valor_pago"
                      >
                        0,00
                      </v-chip>
                      <span v-else>
                        {{ formatPreco(item.valor_pago) }}
                      </span>
                    </td>
                    <td class="text-center">
                      {{ dateFormat(item.data_referente) }}
                    </td>
                    <td class="text-center">
                      <v-chip
                        class="ma-2"
                        color="green"
                        small
                        text-color="white"
                      >
                        {{ dateFormat(item.data_pagamento) }}
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            color="#007744"
            class="mr-4 white--text"
            @click="imprimirExtrato"
          >
            Imprimir extrato
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGerarCartao" max-width="600px">
      <v-card>
        <v-card-title>
          <b>Cartão PET</b>
        </v-card-title>
        <v-divider></v-divider>
        <div class="cartao-pet" id="print">
          <div class="card">
            <img src="img/ct_pet.png" alt="pet" />
            <div class="dados-cartao">
              <p class="texto_cartao_pet">
                <b>PET: {{ mudarLetra(pet.nome_pet) }}</b>
              </p>
              <div class="divide texto_cartao_raca">
                <b>ESPÉCIE: {{ mudarLetra(pet.nome_especie) }}</b>
                <b>RAÇA: {{ mudarLetra(pet.raca) }}</b>
              </div>
              <div class="divide texto_cartao_tutor">
                <b>TUTOR: {{ mudarLetra(titular.nome) }}</b>
              </div>
              <div class="divide texto_cartao_cpf">
                <b>CPF: {{ titular.cpf }}</b>
              </div>
            </div>
          </div>
          <v-btn
            small
            color="warning"
            class="mr-4 white--text"
            @click="printCard()"
          >
            <v-icon dark> mdi-printer</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBaixarParcela" max-width="800px" persistent>
      <v-card>
        <v-card-title><b>Baixar parcela(s)</b> </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pa-8 mx-auto">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                color="#007744"
                v-model="quantidadeParcelaBaixa"
                label="Quantidade de parcelas:"
                type="number"
                required
                @input="() => loadParcelasSelecionadas()"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                color="#007744"
                v-model="baixa.valor_pago"
                label="Valor recebido:"
                :error-messages="errorBaixa.valor_pago"
                type="number"
                required
                :disabled="parcelasSelecionadas.length <= 0"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                color="#007744"
                v-model="baixa.valor_desconto"
                :error-messages="errorBaixa.valor_desconto"
                @input="() => calcularDescontoParcelaBaixa('baixa')"
                label="Valor desconto:"
                :disabled="parcelasSelecionadas.length <= 0"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col
              class="mb-10"
              cols="12"
              md="12"
              v-if="parcelasSelecionadas && parcelasSelecionadas.length > 0"
            >
              <v-btn
                small
                color="#007744"
                class="mr-4 mt-1 white--text"
                @click="baixarParcelas"
                :disabled="carregandoSave"
                :loading="carregandoSave"
              >
                <v-icon dark> mdi-check </v-icon>
                Realizar pagamento
              </v-btn>
            </v-col>

            <v-col cols="12" md="12">
              <div
                v-if="parcelasSelecionadas && parcelasSelecionadas.length > 0"
              >
                <div class="informacoes">
                  <ul>
                    <li>
                      Total com desconto:
                      <b>{{ formatPreco(this.desconto) }}</b>
                    </li>
                    <li>
                      Total sem desconto:
                      <b>{{ formatPreco(calcularTotal()) }}</b>
                    </li>
                    <li>
                      Parcelas em abertos:
                      <b> {{ parcelasSelecionadas.length }}</b>
                    </li>
                  </ul>
                </div>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Parcela</th>
                        <th class="text-center">Valor parcela</th>
                        <th class="text-center">Valor adicional</th>
                        <th class="text-center">valor total</th>
                        <th class="text-center">Mês Referente</th>
                        <th class="text-center">Pagar?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in parcelasSelecionadas"
                        :key="index"
                      >
                        <td class="text-center">{{ item.numero_parcela }}</td>
                        <td class="text-center">
                          {{ formatPreco(item.valor_parcela) }}
                        </td>
                        <td class="text-center">
                          {{ formatPreco(item.valor_adicional) }}
                        </td>
                        <td class="text-center">
                          {{ formatPreco(item.valor_apagar) }}
                        </td>
                        <td class="text-center">
                          {{ dateFormat(item.data_referente) }}
                        </td>
                        <td class="text-center">
                          <v-icon
                            dark
                            color="#007744"
                            @click="modalBaixaUnica(item)"
                          >
                            mdi-currency-usd
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>

          <v-btn small color="error" class="mr-4 mt-10" @click="clearBaixa">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialgoSaveObservacao"
      persistent
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <b>Nova observação</b>
        </v-card-title>
        <v-divider></v-divider>

        <v-form class="pa-5 mx-auto">
          <v-row dense>
            <v-col cols="12" md="12">
              <v-textarea
                color="#007744"
                v-model="observacao.observacao"
                :error-messages="errorObservacao.observacao"
                label="Observação(descritivo):"
                required
                outlined
                dense
              >
              </v-textarea>
            </v-col>

            <v-col>
              <v-autocomplete
                item-value="id"
                item-text="nome"
                label="Categoria:"
                color="#007744"
                :items="categoriasObservacoes"
                v-model="observacao.categoria_observacao_id"
                auto-select-first
                outlined
                dense
                chips
                clearable
                deletable-chips
                :error-messages="errorObservacao.categoria_observacao_id"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-btn
            small
            color="#007744"
            @click="registrarObservacao"
            class="mr-4 white--text"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn small color="error" class="mr-4" @click="clearObservacao">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAlterarContato"
      persistent
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <b>{{ contato && contato.id ? "Alterar" : "Novo" }} Contato</b>
        </v-card-title>
        <v-divider></v-divider>

        <v-form class="pa-5 mx-auto">
          <v-row dense>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="contato.tipo"
                item-value="tipo"
                item-text="tipo"
                label="Forma de contato:"
                color="#007744"
                :items="tipo_contato"
                auto-select-first
                outlined
                dense
                chips
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="contato.contato"
                v-mask="
                  ['WHATSAPP', 'TELEFONE'].includes(contato.tipo)
                    ? '(##) #####-####'
                    : ''
                "
                label="Contato:"
                color="#007744"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            small
            color="#007744"
            class="mr-4 mt-1 white--text"
            @click="alterarContato"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn small color="error" class="mr-4" @click="modalAlterarContato">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPagamentoUnica" max-width="600px">
      <v-card>
        <v-card-title><b>Baixa única</b> </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pl-5 pr-5 pt-5 pb-5 mx-auto">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                color="#007744"
                v-model="baixaUnica.valor_pago"
                :error-messages="errorBaixaUnica.valor_pago"
                label="Valor pago(recebido):"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                color="#007744"
                v-model="baixaUnica.valor_desconto"
                :error-messages="errorBaixaUnica.valor_desconto"
                @input="() => calcularDescontoParcelaBaixa('baixaUnica')"
                label="Valor desconto:"
                :disabled="baixaUnica.valor_pago <= 0"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <div class="info-parcela">
            <ul>
              <li>
                Total com desconto: <b>{{ formatPreco(this.desconto) }}</b>
              </li>
              <li>
                Valor adicional:
                <b> {{ formatPreco(this.baixaUnica.valor_adicional) }}</b>
              </li>
              <li>
                Valor parcela:
                <b>{{ formatPreco(this.baixaUnica.valor_parcela) }}</b>
              </li>
              <li class="primary-text">
                Valor total apagar:
                <b>{{ formatPreco(this.baixaUnica.valor_apagar) }}</b>
              </li>
            </ul>
          </div>

          <v-btn
            small
            color="#007744"
            class="mr-4 mt-1 white--text"
            @click="baixaParcelaUnica"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Realizar pagamento
          </v-btn>

          <v-btn small color="error" class="mr-4 mt-1" @click="clearBaixaUnica">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGerarParcela" max-width="600px">
      <v-card>
        <v-card-title><b>Gerar Parcela(s)</b></v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pa-8 mx-auto">
          <v-row dense>
            <v-col cols="12" md="12">
              <strong>
                <v-checkbox
                  class="ml-5 mt-1"
                  color="success"
                  label="Adesão?"
                  v-model="gerarParcelas.adesao"
                ></v-checkbox>
              </strong>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-if="gerarParcelas.adesao"
                color="#007744"
                v-model="gerarParcelas.valor_adesao"
                :error-messages="errorGerarParcelas.quantidade_parcela"
                label="Valor da Adesão:"
                type="number"
                required
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                v-else
                color="#007744"
                v-model="gerarParcelas.quantidade_parcela"
                :error-messages="errorGerarParcelas.quantidade_parcela"
                label="Quantidade de parcela(s):"
                type="number"
                required
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-btn
            small
            color="#007744"
            class="mr-4 mt-1 white--text"
            @click="gerarParcelasPost"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Gerar
          </v-btn>

          <v-btn
            small
            color="error"
            class="mr-4 mt-1"
            @click="clearGerarParcela"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogParcela" max-width="600px">
      <v-card>
        <v-card-title><b>Alterar parcela</b> </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pa-8 mx-auto">
          <v-row dense>
            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="parcela.valor_parcela"
                :error-messages="errorParcela.valor_parcela"
                label="Valor parcela:"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="parcela.valor_adesao"
                :error-messages="errorParcela.valor_adesao"
                label="Valor adesão:"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="parcela.valor_desconto"
                :error-messages="errorParcela.valor_desconto"
                @input="() => calcularDescontoParcela()"
                label="Valor desconto:"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="parcela.valor_apagar"
                :error-messages="errorParcela.valor_apagar"
                label="valor apagar(total):"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            small
            color="#007744"
            class="mr-4 mt-1 white--text"
            @click="alterarParcela"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn small color="error" class="mr-4 mt-1" @click="clearParcela">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEstornarParcela" max-width="600px">
      <v-card>
        <v-card-title
          ><b>Estornar parcela(s) - selecione uma DATA</b></v-card-title
        >
        <v-divider></v-divider>
        <v-list flat>
          <v-list-item>
            <v-list-item-content>
              <div class="d-flex space">
                <v-text-field
                  class="mt-3 mr-3"
                  color="#007744"
                  v-model="data_pagamento_extorno"
                  label="Data de Pagamento:"
                  type="date"
                  required
                  outlined
                  dense
                ></v-text-field>
              </div>

              <div class="d-flex">
                <v-btn
                  small
                  color="#007744"
                  class="mr-4 mt-1 white--text"
                  @click="estornarBaixas(titular.id)"
                  :disabled="!data_pagamento_extorno"
                >
                  <v-icon dark> mdi-check </v-icon>
                  Estornar
                </v-btn>
                <v-btn
                  small
                  color="error"
                  class="mr-4 mt-1"
                  @click="estornarBaixasParcelas"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPet" max-width="600px">
      <v-card>
        <v-card-title
          ><b>{{ dependente && dependente.id ? "Alterar" : "Novo" }} Pet</b>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pa-8 mx-auto">
          <v-row dense>
            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="dependente.nome_pet"
                :error-messages="error.nome_pet"
                label="Nome do Pet:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="dependente.data_nascimento"
                :error-messages="error.data_nascimento"
                label="Data de Nascimento:"
                type="date"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                color="#007744"
                v-model="dependente.peso"
                :error-messages="error.peso"
                label="Peso:"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                class="mb-5"
                item-value="id"
                item-text="nome"
                label="Espécie:"
                color="#007744"
                v-model="especie_id"
                :items="especies"
                auto-select-first
                outlined
                dense
                chips
                clearable
                deletable-chips
                @input="(value) => loadRacas(value)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                class="mb-5"
                item-value="id"
                item-text="nome"
                label="Raça:"
                color="#007744"
                :items="racas"
                :disabled="racas.length <= 0"
                v-model="dependente.raca_id"
                auto-select-first
                outlined
                dense
                chips
                clearable
                deletable-chips
                :error-messages="error.raca_id"
              ></v-autocomplete>
            </v-col>

            <v-autocomplete
              class="mb-5"
              item-value="nome"
              item-text="nome"
              label="Porte:"
              color="#007744"
              :items="portes"
              v-model="dependente.porte"
              auto-select-first
              outlined
              dense
              chips
              clearable
              deletable-chips
              :error-messages="error.porte"
            ></v-autocomplete>

            <v-col cols="12" md="12">
              <v-switch
                v-model="dependente.is_resgate"
                label="Resgate de cinza?"
                class="pl-3 pt-1"
                color="#007744"
              ></v-switch>
            </v-col>

            <v-col
              cols="12"
              md="12"
              v-if="
                dependente &&
                dependente.id &&
                dependente.data_obito === null &&
                dependente.is_ativo
              "
            >
              <v-alert class="mb-10" dense outlined type="error">
                Deseja registrar <strong> óbito. (CAMPOS OBRIGATÓRIOS)</strong>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-text-field
                      class="mt-3"
                      color="#007744"
                      v-model="data_obito"
                      :error-messages="error.data_obito"
                      label="Data óbito:"
                      type="date"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>

          <v-btn
            small
            color="#007744"
            class="mr-4 mt-1 white--text"
            @click="salvarDependente"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn
            small
            color="error"
            class="mr-4 mt-1"
            @click="clearDependentes"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGerarBoleto" max-width="700px">
      <v-card>
        <v-card-title><b>Novo boleto</b></v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pa-8">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                color="#007744"
                v-model="novoBoleto.cancelar_anteriores"
                :error-messages="errorNovoBoleto.cancelar_anteriores"
                required
                outlined
                dense
                :items="[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]"
                item-text="label"
                item-value="value"
                label="Cancelar boletos anteriores?"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                color="#007744"
                v-model="novoBoleto.forma_pagamento"
                :error-messages="errorNovoBoleto.forma_pagamento"
                required
                outlined
                dense
                :items="tiposBoletos"
                label="Tipo Boleto:"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                color="#007744"
                v-model="novoBoleto.qtd_parcelas"
                :error-messages="errorNovoBoleto.qtd_parcelas"
                label="Quantidade de parcela(s):"
                type="number"
                required
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                color="#007744"
                v-model="novoBoleto.data_vencimento"
                :error-messages="errorNovoBoleto.data_vencimento"
                label="Data Vencimento"
                type="date"
                required
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-btn
            small
            color="#007744"
            class="mr-4 mt-1 white--text"
            @click="salvarBoletoGerar"
            :loading="carregamentoGerarBoleto"
          >
            <v-icon dark> mdi-check </v-icon>
            Gerar e enviar
          </v-btn>

          <v-btn
            small
            color="error"
            class="mr-4 mt-1"
            @click="clearModalGerarBoleto"
            :disabled="carregamentoGerarBoleto"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-card id="topo">
      <div class="d-flex align-center auto">
        <v-toolbar dark dense color="#007744">
          <v-btn small icon @click="fecharModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            ><b>Contrato {{ titular.contrato }} </b> -
            {{ titular.descricao }}</v-toolbar-title
          >
        </v-toolbar>
      </div>

      <div class="pa-4">
        <v-alert v-if="titular && titular.is_permuta" type="warning">
          Esse contrato está como <strong>PERMUTA.</strong>
          Então, não irá gerar cobrança e nem entrará nos relatórios.
        </v-alert>

        <v-alert
          v-if="titular && titular.is_novo && !titular.is_permuta"
          type="warning"
        >
          Contrato em <strong> carência </strong> por 90 dias.
        </v-alert>

        <v-alert
          v-if="titular && titular.carencia && !titular.is_permuta"
          type="warning"
        >
          Contrato em <strong> carência </strong> por atraso.
        </v-alert>

        <v-row dense>
          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-card outlined>
                  <v-card-title class="titulo">
                    <b>Dados pessoais:</b>
                  </v-card-title>

                  <v-list flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title  
                          ><b>Nome completo:</b>
                          {{ titular.nome }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>CPF:</b> {{ titular.cpf }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>RG:</b> {{ titular.rg }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Cidade:</b>
                          {{ titular._cidade }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Estado:</b>
                          {{ titular._estado }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Endereço:</b>
                          {{ titular.rua_endereco }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Bairro:</b>
                          {{ titular.bairro_endereco }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Número/Lote:</b>
                          {{ titular.numero_lote_endereco }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Forma de pagamento:</b>
                          {{ titular.forma_pagamento }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Dia Vencimento:</b>
                          {{ titular.dia_vencimento }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Assinatura de Contrato:</b>
                          {{
                            dateFormat(titular.data_assinatura_contrato)
                          }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Primeira Mensalidade:</b>
                          {{
                            dateFormat(titular.data_primeira_mensalidade)
                          }}</v-list-item-title
                        >

                        <v-list-item-title class="mt-1"
                          ><b>Último Pagamento:</b>
                          {{
                            dateFormat(titular.ultimo_pagamento)
                          }}</v-list-item-title
                        >

                        <v-list-item-title
                          class="mt-1"
                          v-if="titular.data_cancelamento != null"
                          ><b>PLANO CANCELADO EM:</b>
                          {{
                            dateFormat(titular.data_cancelamento)
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <fieldset class="ma-4">
                    <v-card class="elevation-0">
                      <v-card-title class="titulo">
                        <b>Contato(s)</b>
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="#007744"
                          class="white--text"
                          @click="modalAlterarContato({})"
                          fab
                          :disabled="titular.data_cancelamento != null"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Tipo</th>
                              <th class="text-left">Contato</th>
                              <th class="text-center">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="contato in titular.contatos"
                              :key="contato.id"
                            >
                              <td class="text-left">{{ contato.tipo }}</td>
                              <td class="text-left">{{ contato.contato }}</td>
                              <td
                                class="text-center"
                                v-if="titular.data_cancelamento === null"
                              >
                                <v-menu bottom left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      small
                                      dark
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="black"
                                    >
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item
                                      @click="modalAlterarContato(contato)"
                                    >
                                      <v-list-item-title
                                        >Alterar</v-list-item-title
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      @click="excluirContato(contato.id)"
                                    >
                                      <v-list-item-title
                                        >Excluir</v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </fieldset>
                  <fieldset class="ma-4">
                    <v-card class="elevation-0">
                      <v-card-title class="titulo">
                        <b>Arquivo(s)</b>
                      </v-card-title>
                      <div v-if="groupedAnexos.length == 0">
                        <v-chip
                          label
                          outlined
                          class="v-chip-size-titular-contrato"
                          color="warning"
                        >
                          Nenhum <strong>&nbsp;Contrato&nbsp;</strong> associado
                          a este <strong>&nbsp;Titular!</strong>
                        </v-chip>
                      </div>
                      <v-simple-table v-else>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Documento</th>
                              <th class="text-center">Arquivo(s)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(group, tipo) in groupedAnexos"
                              :key="tipo"
                            >
                              <td class="text-left">{{ tipo }}</td>
                              <td class="text-center">
                                <v-menu bottom left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      small
                                      dark
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="black"
                                    >
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item
                                      v-for="termo in group"
                                      :key="termo.id"
                                      @click.prevent="visualizarTermo(termo.id)"
                                    >
                                      <v-list-item-title>{{
                                        termo.nome
                                      }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </fieldset>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <v-row dense>
              <v-col cols="12" md="126">
                <v-card class="mb-1" outlined>
                  <v-card-title class="titulo">
                    <b>({{ dependentes.length }}) - Dependentes</b>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      color="#007744"
                      class="white--text"
                      @click="clearDependentes"
                      fab
                      :disabled="titular.data_cancelamento != null"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-card-title>

                  <found
                    v-if="!carregamentoDependentes && dependentes.length === 0"
                  />

                  <loading v-else-if="carregamentoDependentes" />

                  <v-simple-table v-else dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Porte</th>
                          <th class="text-left">Raça</th>
                          <th class="text-left">Data Óbito</th>
                          <th class="text-left">Incluso em:</th>
                          <th
                            class="text-center"
                            v-if="titular.data_cancelamento === null"
                          >
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in dependentes"
                          :key="index"
                          :class="
                            !item.is_ativo
                              ? 'deletado'
                              : item.data_obito
                              ? 'obito'
                              : item.is_carencia
                              ? 'carencia'
                              : ''
                          "
                        >
                          <td class="text-left">{{ item.id }}</td>
                          <td class="text-left">{{ item.nome_pet }}</td>
                          <td class="text-left">{{ item.porte }}</td>
                          <td class="text-left">{{ item.raca }}</td>
                          <td class="text-left">
                            <span v-if="item.data_obito != null">
                              {{ dateFormat(item.data_obito) }}</span
                            >
                            <span v-else>Óbito não registrado</span>
                          </td>
                          <td class="text-left">
                            {{ dateFormat(item.created_at) }}
                          </td>
                          <td
                            class="text-center"
                            v-if="titular.data_cancelamento === null"
                          >
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  dark
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="black"
                                >
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item @click="editarDependente(item)">
                                  <v-list-item-title
                                    >Alterar informações</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item
                                  v-if="!item.data_obito"
                                  @click="modalGerarCartaoPet(item)"
                                >
                                  <v-list-item-title
                                    >Gerar carteirinha</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item
                                  class="estornarObito"
                                  v-if="item && item.data_obito != null"
                                  @click="estornarObito(item.id)"
                                >
                                  <v-list-item-title
                                    >Estornar óbito</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item
                                  v-if="item.is_ativo"
                                  @click="excluirDependente(item.id)"
                                >
                                  <v-list-item-title>Excluir</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="ml-4 mt-4 mb-4">
                    <div class="mb-1">
                      <b>Legenda:</b>
                    </div>
                    <v-row dense>
                      <v-col cols="12">
                        <v-chip
                          small
                          label
                          color="rgba(248, 248, 117, 0.904)"
                          class="mr-1"
                        ></v-chip>
                        Em carência

                        <v-chip
                          color="rgba(212, 97, 89, 0.904)"
                          label
                          small
                          class="ml-3 mr-1"
                        ></v-chip
                        >Excluido

                        <v-chip
                          color="rgba(211, 157, 7, 0.904)"
                          label
                          small
                          class="ml-3 mr-1"
                        ></v-chip
                        >Óbito
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="12" v-if="titular && !titular.is_permuta">
                <v-card class="mb-1" outlined>
                  <v-card-title class="titulo">
                    <b>({{ parcelas.length }}) - Parcelas</b>
                    <v-spacer></v-spacer>
                    <v-btn
                      title="Gerar boleto"
                      small
                      color="#007744"
                      class="white--text mr-2"
                      fab
                      @click="clearModalGerarBoleto"
                    >
                      <v-icon dark>mdi-file-document </v-icon>
                    </v-btn>
                    <v-btn
                      title="Estornar baixa parcela"
                      small
                      color="#007744"
                      class="white--text mr-2"
                      fab
                      :disabled="titular.data_cancelamento != null"
                      @click="estornarBaixasParcelas"
                    >
                      <v-icon dark>mdi-cached</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      title="Extrato pagamentos"
                      color="#007744"
                      class="white--text mr-2"
                      fab
                      :disabled="titular.data_cancelamento != null"
                      @click="modalExtrato"
                    >
                      <v-icon dark>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      title="Baixar parcelas"
                      color="#007744"
                      class="white--text mr-2"
                      @click="modalParcelaSelecionada"
                      fab
                      :disabled="titular.data_cancelamento != null"
                    >
                      <v-icon dark> mdi-currency-usd </v-icon>
                    </v-btn>
                    <v-btn
                      small
                      title="Gerar parcelas"
                      color="#007744"
                      class="white--text"
                      @click="clearGerarParcela"
                      :disabled="titular.data_cancelamento != null"
                      fab
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-card-title>
                  <found
                    v-if="!carregamentoParcelas && parcelas.length === 0"
                  />
                  <loading v-else-if="carregamentoParcelas" />
                  <div v-else>
                    <v-simple-table dense fixed-header height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Parcela</th>
                            <th class="text-center">Valor parcela</th>
                            <th class="text-center">Valor desconto</th>
                            <th class="text-center">Valor adicional</th>
                            <th class="text-center">Valor total</th>
                            <th class="text-center">Valor pago</th>
                            <th class="text-center">Mês Referente</th>
                            <th class="text-center">Usuário</th>
                            <th class="text-center">Pago?</th>
                            <th
                              class="text-center"
                              v-if="titular.data_cancelamento === null"
                            >
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in parcelas"
                            :key="index"
                            :class="item.is_ativo ? '' : 'deletado'"
                          >
                            <td class="text-center">
                              {{ item.numero_parcela }}
                            </td>
                            <td class="text-center">
                              {{ formatPreco(item.valor_parcela) }}
                            </td>
                            <td class="text-center">
                              {{ formatPreco(item.valor_desconto) }}
                            </td>
                            <td class="text-center">
                              {{ formatPreco(item.valor_adicional) }}
                            </td>
                            <td class="text-center">
                              {{ formatPreco(item.valor_apagar) }}
                            </td>
                            <td class="text-center">
                              <v-chip
                                class="ma-2"
                                color="red"
                                small
                                text-color="white"
                                v-if="!item.valor_pago"
                              >
                                0,00
                              </v-chip>
                              <span v-else>
                                {{ formatPreco(item.valor_pago) }}
                              </span>
                            </td>
                            <td class="text-center">
                              <v-edit-dialog
                                @save="() => alterarDatasParcelas(item)"
                              >
                                <template v-slot:input>
                                  <v-text-field
                                    class="input"
                                    v-model="item.data_referente"
                                    label="Alterar data de vencimento:"
                                    type="date"
                                    color="#007744"
                                    single-line
                                  ></v-text-field>
                                </template>
                                {{ item.data_referente }}
                              </v-edit-dialog>
                            </td>
                            <td class="text-center">
                              <v-chip
                                class="ma-2"
                                color="error"
                                small
                                text-color="white"
                                v-if="item && !item.nome"
                              >
                                /N
                              </v-chip>
                              <span>{{ item.nome }}</span>
                            </td>
                            <td class="text-center">
                              <v-edit-dialog
                                v-if="item && item.data_pagamento != null"
                                @save="() => alterarDatasParcelas(item)"
                              >
                                <template v-slot:input>
                                  <v-text-field
                                    v-model="item.data_pagamento"
                                    label="Alterar data de pagamento:"
                                    color="#007744"
                                    type="date"
                                    single-line
                                  ></v-text-field>
                                </template>
                                <v-chip
                                  class="ma-2"
                                  color="green"
                                  small
                                  text-color="white"
                                >
                                  {{ item.data_pagamento }}
                                </v-chip>
                              </v-edit-dialog>

                              <v-chip
                                class="ma-2"
                                color="red"
                                small
                                text-color="white"
                                v-else
                              >
                                /N
                              </v-chip>
                            </td>
                            <td
                              class="text-center"
                              v-if="titular.data_cancelamento === null"
                            >
                              <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    small
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="black"
                                  >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-list v-if="item && !item.data_pagemento">
                                  <v-list-item @click="editarParcela(item)">
                                    <v-list-item-title
                                      >Alterar</v-list-item-title
                                    >
                                  </v-list-item>

                                  <v-list-item @click="excluirParcela(item.id)">
                                    <v-list-item-title
                                      >Excluir</v-list-item-title
                                    >
                                  </v-list-item>

                                  <v-list-item
                                    @click="modalBaixaUnica(item, false)"
                                    v-if="item && item.data_pagamento === null"
                                  >
                                    <v-list-item-title
                                      >Baixar</v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-left mt-5">
                      <v-pagination
                        v-model="paginationParcelas.page"
                        :length="paginationParcelas.lastPage"
                        :total-visible="paginationParcelas.perPage"
                        color="#007744"
                        @input="(page) => loadParcelas(page)"
                        v-if="parcelas.length > 0"
                        @next="() => loadParcelas(paginationParcelas.page)"
                        @previous="() => loadParcelas(paginationParcelas.page)"
                      ></v-pagination>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="12">
                <v-card class="mb-1" outlined>
                  <v-card-title class="titulo">
                    <b>Observações(F9)</b>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      color="#007744"
                      class="white--text"
                      @click="clearObservacao"
                      fab
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-card-title>
                  <found v-if="observacoes.length === 0" />
                  <loading v-else-if="carregamentoObservacoes" />
                  <div v-else>
                    <v-simple-table dense fixed-header height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">Usuario</th>
                            <th class="text-left">Observação</th>
                            <th class="text-left">Registrado:</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in observacoes" :key="index">
                            <td class="text-center">
                              {{ item.id }}
                            </td>
                            <td class="text-left">
                              {{ item._usuario }}
                            </td>
                            <td class="text-left">
                              {{ item.observacao }}
                            </td>
                            <td class="text-left">
                              {{ dateFormat(item.created_at) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-left mt-5">
                      <v-pagination
                        v-model="paginationoObservacoes.page"
                        :length="paginationoObservacoes.lastPage"
                        :total-visible="paginationoObservacoes.perPage"
                        color="#007744"
                        @input="(page) => loadObservacoes(page)"
                        v-if="observacoes.length > 0"
                        @next="
                          () => loadObservacoes(paginationoObservacoes.page)
                        "
                        @previous="
                          () => loadObservacoes(paginationoObservacoes.page)
                        "
                      ></v-pagination>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
  <loading v-else />
</template>

<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";
import global from "../../url.api.js";

const moment = require("moment");

export default {
  data: () => ({
    global: global,
    dialogGerarBoleto: false,
    data_obito: null,
    dialogBaixarParcela: false,
    dialogPagamentoUnica: false,
    parcelaID: null,
    especie_id: null,
    quantidadeParcelaBaixa: 0,
    parcelasSelecionadas: [],
    estado_id: null,
    tiposBoletos: ["Boleto Avulso", "Boleto Agrupado"],
    novoBoleto: {
      contrato: 0,
      forma_pagamento: "Boleto Avulso",
      data_vencimento: null,
      qtd_parcelas: 1,
      cancelar_anteriores: true,
    },
    errorNovoBoleto: {
      contrato: 0,
      forma_pagamento: null,
      data_vencimento: null,
      qtd_parcelas: null,
      cancelar_anteriores: null,
    },
    baixa: {
      valor_pago: 0,
      valor_desconto: 0,
      quantidade_parcela: 0,
      titular_id: 0,
    },
    errorBaixa: {
      valor_pago: 0,
      valor_desconto: 0,
      quantidade_parcela: 0,
      titular_id: 0,
    },
    baixaUnica: {
      valor_pago: 0,
      valor_desconto: 0,
      titular_id: null,
    },
    errorBaixaUnica: {
      valor_pago: 0,
      valor_desconto: 0,
      titular_id: null,
    },
    carregamentoparcelasSelecionadas: false,
    carregamentoParcelas: false,
    carregamentoObservacoes: false,
    carregamentoDependentes: false,
    carregamentoGerarBoleto: false,
    carregamentoArquivos: false,
    carrementoModalDetlhar: true,
    dialogPet: false,
    titulo: "Cadastrar Novo titular",
    dependentes: [],
    parcelas: [],
    parcela: {
      valor_parcela: 0,
      valor_adesao: 0,
      valor_apagar: 0,
      valor_desconto: 0,
    },
    errorParcela: {
      valor_parcela: 0,
      valor_adesao: 0,
      valor_apagar: 0,
      valor_desconto: 0,
    },
    dialogParcela: false,
    gerarParcelas: {
      quantidade_parcela: null,
      plano_id: null,
      titular_id: null,
      adesao: false,
      valor_adesao: null,
    },
    errorGerarParcelas: {
      quantidade_parcela: null,
      plano_id: null,
      titular_id: null,
    },
    dialogGerarParcela: false,
    paginationParcelas: {
      page: 1,
      perPage: 1,
      lastPage: 1,
    },
    dialogDetalhar: true,
    especies: [],
    racas: [],
    tipo_contato: [
      { tipo: "EMAIL" },
      { tipo: "TELEFONE" },
      { tipo: "WHATSAPP" },
    ],
    formasPagamentos: [{ id: 1, nome: "BOLETO" }],
    portes: [
      { id: 0, nome: "P" },
      { id: 1, nome: "M" },
      { id: 2, nome: "G" },
      { id: 3, nome: "GG" },
    ],
    dependente: {
      raca_id: null,
      titular_id: null,
      nome_pet: null,
      peso: null,
      porte: null,
      data_obito: null,
      is_resgate: false,
      data_nascimento: null,
    },
    error: {
      raca_id: null,
      titular_id: null,
      nome_pet: null,
      peso: null,
      data_obito: null,
      porte: null,
      is_resgate: false,
    },
    titular: {
      plano_id: null,
      cidade_id: null,
      nome: null,
      cpf: null,
      rg: null,
      rua_endereco: null,
      bairro_endereco: null,
      dia_vencimento: null,
      forma_pagamento: null,
      numero_lote_endereco: null,
      data_cancelamento: null,
      data_assinatura_contrato: null,
      data_primeira_mensalidade: null,
      justificativa_cancelamento: null,
      is_permuta: false,
      is_ativo: true,
      contatos: [
        {
          tipo: "",
          contato: "",
        },
      ],
    },
    carregandoSave: false,
    dialogAlterarContato: false,
    contato: {},
    errorContato: {},
    dependenteID: null,
    buscar: {
      tipo: "cpf",
      search: "",
    },
    dialogGerarCartao: false,
    pet: {},
    observacoes: [],
    paginationoObservacoes: {
      page: 1,
      perPage: 1,
      lastPage: 1,
    },
    categoriasObservacoes: [],
    dialgoSaveObservacao: false,
    observacao: {
      categoria_observacao_id: null,
      observacao: null,
    },
    errorObservacao: {
      categoria_observacao_id: null,
      observacao: null,
    },
    valorParcelaOriginal: 0,
    dialogExtrato: false,
    carregamentoExtrato: true,
    extrato: [],
    paramsExtrato: {
      inicio: moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
      fim: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
    },
    user: null,
    desconto: 0,
    tipoPagamento: null,
    novaDataVencimento: null,
    /// Estornar baixas
    dialogEstornarParcela: false,
    data_pagamento_extorno: null,
    /// Para anexar diferentes tipos de contratos
    nomeContratosAnexados: null,
    selectedContratos: [],
    anexos: [],
    tipo_contratos: [
      {
        id: 1,
        nome: "Contrato PET Primavera - Dourados - Plano PET individualizado",
      },
      {
        id: 2,
        nome: "TABLET VENDAS - TERMO DE INCLUSAO DE DEPENDENTE PET PRIMAVERA ASSOCIADO",
      },
      {
        id: 3,
        nome: "PET PRIMAVERA - DECLARACAO DE EXCLUSAO DE DEPENDENTE PET ASSOCIADO",
      },
      {
        id: 4,
        nome: "PET PRIMAVERA - DECLARACAO DE EXCLUSAO DE DEPENDENTE PET PARTICULAR",
      },
      {
        id: 5,
        nome: "PET PRIMAVERA - DECLARACAO DE INCLUSAO DE DEPENDENTE PET PARTICULAR",
      },
    ],

    nomeTermos: [],
    nenhumArquivo: "",
  }),
  methods: {
    async alterarDatasParcelas(item) {
      if (!item) return false;

      let data = {
        titular_id: item.titular_id,
      }; 

      if (item && item.data_pagamento != null)
        data["data_pagamento"] = moment(item.data_pagamento, 'YYYY-MM-DD').format('YYYY-MM-DD');

      if (item && item.data_referente != null)
        data["data_referente"] = moment(item.data_referente, 'YYYY-MM-DD').format('YYYY-MM-DD');

      try {
        const response = await axios.post(
          `srv-sistema/api/v1/pet-primavera/parcelas/alterar-datas/${item.id}`,
          data
        );

        if (response.data.status) this.loadParcelas();
      } catch (res) {
        if (res.response.data && res.response.data.message) {
          this.$toast(res.response.data.message, {
            type: "error",
          });
        } else {
          this.$toast(res.toString(), {
            type: "error",
          });
        }
        this.loadParcelas();
      }
    },
    async imprimirExtrato() {
      return await this.$htmlToPaper("extrato", {
        name: "_blank",
        specs: [],
        styles: ["css/extrato.css"],
        timeout: 1000,
        autoClose: true,
        windowTitle: window.document.title,
      });
    },
    calcularTotalParcelasPagas(array) {
      if (array && array.length === 0) return 0;
      return array
        .map((item) => item.valor_pago)
        .reduce((prev, next) => prev + next);
    },
    dataAtual() {
      return moment().format("DD/MM/YYYY");
    },
    async visualizarTermo(id) {
      await this.carregarTermo(id);
      const termo = this.anexos;
      const fileURL = termo[0].url;
      window.open(fileURL, "_blank");
    },
    async carregarNomeTermo(id) {
      const nomes = await axios.get(
        `srv-sistema/api/v1/pet-primavera/cliente/carregar-nomes-termos/${id}`
      );
      if (nomes.data.status) {
        this.nomeTermos = nomes.data.data;
      } else {
        this.nenhumArquivo =
          "Nenhum tipo de Contrato associado a este Titular!";
      }
    },
    async carregarTermo(id) {
      await axios
        .get(
          `srv-sistema/api/v1/pet-primavera/cliente/carregar-termo-cancelamento/${id}`
        )
        .then((res) => {
          this.anexos = res.data.data;
          return this.anexos;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.status === false
          ) {
            return;
          } else {
            this.$toast("Não foi possivel carregar termos!", {
              type: "error",
            });
          }
        });
    },
    modalExtrato() {
      this.paramsExtrato.titular = this.titular.id;
      this.dialogExtrato = !this.dialogExtrato;
      this.loadExtrato();
    },
    calcularDescontoParcela() {
      if (this.valorParcelaOriginal === 0) {
        this.valorParcelaOriginal = this.parcela.valor_apagar;
      }

      if (!this.valorParcelaOriginal) {
        return (this.valorParcelaOriginal = this.valorParcelaOriginal);
      }

      this.parcela.valor_apagar =
        this.valorParcelaOriginal - this.parcela.valor_desconto;
    },
    calcularDescontoParcelaBaixa(campo) {
      this.desconto = parseFloat(
        this[campo].valor_pago - this[campo].valor_desconto
      );
    },
    formatPreco(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    dateFormatInput(param) {
      return moment(param).format("YYYY-MM-DD");
    },
    modalAlterarContato(contato) {
      this.dialogAlterarContato = !this.dialogAlterarContato;
      this.contato = contato;
      this.errorContato = {};
    },
    async loadExtrato() {
      this.extrato = [];
      this.carregamentoExtrato = true;

      const extrato = await axios.get(
        "srv-sistema/api/v1/pet-primavera/extrato/parcelas-titular",
        {
          params: this.paramsExtrato,
        }
      );

      if (extrato.data.status) {
        this.extrato = extrato.data.extrato;
        this.carregamentoExtrato = false;
      }
    },
    async verificarContrato() {
      await axios.get(
        `srv-sistema/api/v1/pet-primavera/cliente/verificar-contrato/${this.titular.id}`
      );
    },
    modalParcelaSelecionada() {
      this.dialogBaixarParcela = !this.dialogBaixarParcela;
      this.baixa = {};
      this.errorBaixa = {};
      this.desconto = 0;
      this.baixa.valor_desconto = 0;
    },
    loadParcelasSelecionadas() {
      if (!this.quantidadeParcelaBaixa) return false;
      this.carregamentoparcelasSelecionadas = true;
      axios
        .get(
          `srv-sistema/api/v1/pet-primavera/parcelas/selecionar/${this.quantidadeParcelaBaixa}/${this.titular.id}`
        )
        .then((response) => {
          if (response.data.status) {
            this.parcelasSelecionadas = response.data.parcelas;
            this.carregamentoparcelasSelecionadas = false;
          }
        });
    },
    calcularTotal() {
      return this.parcelasSelecionadas
        .map((item) => item.valor_apagar)
        .reduce((prev, next) => prev + next);
    },
    loadCategorias() {
      axios
        .get("srv-sistema/api/v1/pet-primavera/categorias-observacoes/all")
        .then((response) => {
          if (response.data.status) {
            this.categoriasObservacoes = response.data.categorias;
          }
        });
    },
    loadEspecies() {
      axios
        .get("srv-sistema/api/v1/pet-primavera/especies-sem-pagination")
        .then((response) => {
          if (response.data.status) {
            this.especies = response.data.especies;
          }
        });
    },
    loadRacas(id) {
      axios
        .get(`srv-sistema/api/v1/pet-primavera/racas-selecionar-especie/${id}`)
        .then((response) => {
          if (response.data.status) {
            this.racas = response.data.racas;
          }
        });
    },
    loadCidades(id) {
      axios
        .get(`srv-sistema/api/v1/pet-primavera/cidades-selecionar-estado/${id}`)
        .then((response) => {
          if (response.data.status) {
            this.cidades = response.data.cidades;
          }
        });
    },
    loadDependentes() {
      this.carregamentoDependentes = true;

      axios
        .get(`srv-sistema/api/v1/pet-primavera/dependentes/${this.titular.id}`)
        .then((response) => {
          if (response.data.status) {
            this.dependentes = response.data.dependentes;
            this.carregamentoDependentes = false;
          }
        });
    },
    loadParcelas(params) {
      this.carregamentoParcelas = true;

      axios
        .get(`srv-sistema/api/v1/pet-primavera/parcelas/${this.titular.id}`, {
          params: {
            page: params,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.parcelas = response.data.parcelas.data;

            this.paginationParcelas = {
              page: response.data.parcelas.page,
              perPage: response.data.parcelas.perPage,
              lastPage: response.data.parcelas.lastPage,
              total: response.data.parcelas.total,
            };

            this.carregamentoParcelas = false;
          }
        });
    },
    async loadObservacoes(params) {
      this.carregamentoObservacoes = true;

      const observacoes = await axios.get(
        `srv-sistema/api/v1/pet-primavera/observacoes/${this.titular.id}`,
        {
          params: {
            page: params,
          },
        }
      );

      if (observacoes.data.status) {
        this.observacoes = observacoes.data.observacoes.data;

        this.paginationoObservacoes = {
          page: observacoes.data.observacoes.page,
          perPage: observacoes.data.observacoes.perPage,
          lastPage: observacoes.data.observacoes.lastPage,
          total: observacoes.data.observacoes.total,
        };
        this.carregamentoObservacoes = false;
      }
    },
    async modalDetalharCarregar(item) {
      this.titular = item;

      this.dependentes = [];
      this.parcelas = [];
      this.observacoes = [];

      this.verificarContrato();
      this.loadDependentes();
      this.loadParcelas();
      this.loadObservacoes();
      this.loadEspecies();
      this.carregarNomeTermo(this.titular.id);

      this.carrementoModalDetlhar = false;
    },
    editarDependente(item) {
      this.dialogPet = !this.dialogPet;
      this.dependente = item;
      this.titulo = item && item.id ? "Alterar PET" : "Novo Dependente";
      this.data_cancelamento = null;

      if (item && item.id) {
        this.especie_id = item.especie_id;
        this.loadRacas(this.especie_id);
      }
    },
    estornarBaixasParcelas() {
      this.dialogEstornarParcela = !this.dialogEstornarParcela;
    },
    async estornarBaixas(id) {
      const dataPagamento = new Date(this.data_pagamento_extorno);
      const dataFormatada = dataPagamento.toISOString().split("T")[0];

      await axios
        .post(`srv-sistema/api/v1/pet-primavera/parcelas/estornar/${id}`, {
          data_pagamento: dataFormatada,
        })
        .then((res) => {
          if (res.data.status) {
            this.dialogEstornarParcela = false;
            Swal.fire({
              title: "Sucesso!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });

            this.loadParcelas();
          }
        })
        .catch((error) => {
          if (error.response.data && error.response.data.validation) {
            this.errorContato = error.response.data.validation;
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    editarParcela(item) {
      this.dialogParcela = !this.dialogParcela;
      this.parcela = item;
    },
    modalBaixaUnica(item, abrir = true) {
      if (item && item.data_pagamento != null) return false;
      if (abrir) this.dialogBaixarParcela = !this.dialogBaixarParcela;

      this.dialogPagamentoUnica = !this.dialogPagamentoUnica;
      this.parcelaID = item.id;
      this.baixaUnica = item;
      this.baixaUnica.valor_desconto = 0;
      this.baixaUnica.titular_id = item.titular_id;
      this.desconto = 0;
    },
    modalGerarCartaoPet(pet) {
      this.pet = pet;
      this.dialogGerarCartao = !this.dialogGerarCartao;
    },
    alterarContato() {
      this.carregandoSave = true;

      let contato = this.contato.id
        ? this.contato
        : {
            contato: this.contato.contato,
            tipo: this.contato.tipo,
            titular_id: this.titular.id,
          };

      let url = this.contato.id
        ? "srv-sistema/api/v1/pet-primavera/titular/contato-alterar/" +
          this.contato.id
        : "srv-sistema/api/v1/pet-primavera/titular/contato-salvar";
      axios
        .post(url, contato)
        .then((response) => {
          if (response.data.status) {
            this.$toast(response.data.message, {
              type: "success",
            });
          }

          window.location.reload();
        })
        .catch((error) => {
          if (error.response.data && error.response.data.validation) {
            this.errorContato = error.response.data.validation;
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    registrarObservacao() {
      this.carregandoSave = true;

      axios
        .post("srv-sistema/api/v1/pet-primavera/observacao/salvar", {
          titular_id: this.titular.id,
          categoria_observacao_id: this.observacao.categoria_observacao_id,
          observacao: this.observacao.observacao,
        })
        .then((response) => {
          if (response.data.status) {
            this.$toast(response.data.message, {
              type: "success",
            });
          }

          this.loadObservacoes();
          this.clearObservacao();
          this.carregandoSave = false;
        })
        .catch((error) => {
          if (error.response.data && error.response.data.validation) {
            this.errorContato = error.response.data.validation;
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    alterarParcela() {
      this.carregandoSave = true;

      axios
        .post(
          `srv-sistema/api/v1/pet-primavera/parcelas/alterar/${this.parcela.id}`,
          {
            valor_parcela: this.parcela.valor_parcela,
            valor_adesao: this.parcela.valor_adesao,
            valor_apagar: this.parcela.valor_apagar,
            valor_desconto: this.parcela.valor_desconto,
          }
        )
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.clearParcela();
            this.loadParcelas();

            this.carregandoSave = false;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
            this.carregandoSave = false;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    baixarParcelas() {
      this.carregandoSave = true;

      Swal.fire({
        title: "Realizar baixa da(s) parcela(s)",
        text: "Deseja realizar essa ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          axios
            .post(`srv-sistema/api/v1/pet-primavera/parcelas/baixar`, {
              valor_pago: this.baixa.valor_pago,
              quantidade_parcela: this.quantidadeParcelaBaixa,
              titular_id: this.titular.id,
              valor_desconto: this.baixa.valor_desconto,
            })
            .then((res) => {
              if (res.data.status) {
                this.$toast(res.data.message, {
                  type: "success",
                });

                this.clearBaixa();
                this.loadParcelas();

                this.carregandoSave = false;
              } else {
                this.$toast(res.data.message, {
                  type: "error",
                });
                this.carregandoSave = false;
              }
            })
            .catch((res) => {
              if (res.response.data && res.response.data.validation) {
                this.errorBaixa = res.response.data.validation;

                this.$toast(res.response.data.message, {
                  type: "error",
                });
              } else {
                this.$toast(res.response.data.message, {
                  type: "error",
                });
              }
              this.carregandoSave = false;
            });
        } else {
          this.carregandoSave = false;
        }
      });
    },
    estornarObito(id) {
      this.carregandoSave = true;

      Swal.fire({
        title: "Estorno de óbito!",
        text: "Deseja realizar essa ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            axios
              .post(
                `srv-sistema/api/v1/pet-primavera/dependente/estornar-obito/${id}`
              )
              .then((res) => {
                if (res.data.status) {
                  this.$toast(res.data.message, {
                    type: "success",
                  });

                  this.carregandoSave = false;
                  this.loadDependentes(this.titular.id);
                  this.loadObservacoes();
                } else {
                  this.$toast(res.data.message, {
                    type: "error",
                  });
                  this.carregandoSave = false;
                }
              })
              .catch((res) => {
                if (res.response.data && res.response.data.validation) {
                  this.$toast(res.response.data.message, {
                    type: "error",
                  });
                } else {
                  this.$toast(res.response.data.message, {
                    type: "error",
                  });
                }
                this.carregandoSave = false;
              });
          } else {
            this.carregandoSave = false;
          }
        })
        .catch(() => {
          this.$toast("Sem permissão para executar esse comando!", {
            type: "error",
          });
        });
    },
    baixaParcelaUnica() {
      this.carregandoSave = true;

      Swal.fire({
        title: "Realizar baixa da(s) parcela(s)",
        text: "Deseja realizar essa ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `srv-sistema/api/v1/pet-primavera/parcelas/baixa-unica/${this.parcelaID}`,
              {
                valor_pago: this.baixaUnica.valor_pago,
                valor_desconto: this.baixaUnica.valor_desconto,
                titular_id: this.baixaUnica.titular_id,
              }
            )
            .then((res) => {
              if (res.data.status) {
                this.$toast(res.data.message, {
                  type: "success",
                });

                this.clearBaixaUnica();
                this.loadParcelas();

                this.carregandoSave = false;
              } else {
                this.$toast(res.data.message, {
                  type: "error",
                });
                this.carregandoSave = false;
              }
            })
            .catch((res) => {
              if (res.response.data && res.response.data.validation) {
                this.errorBaixa = res.response.data.validation;

                this.$toast(res.response.data.message, {
                  type: "error",
                });
              } else {
                this.$toast(res.response.data.message, {
                  type: "error",
                });
              }
              this.carregandoSave = false;
            });
        } else {
          this.carregandoSave = false;
        }
      });
    },
    loadModal() {
      this.parcelas = [];
      this.dependente = {};
      this.error = {};
      this.data_obito = null;
      this.dialogPet = false;
      this.carregandoSave = false;
    },
    excluirDependente(id) {
      Swal.fire({
        title: `Dependente será excluido`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `srv-sistema/api/v1/pet-primavera/dependente/deletar/${id}/${this.titular.id}/${this.titular.plano_id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });

            this.loadModal();
            this.loadParcelas();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    excluirContato(id) {
      Swal.fire({
        title: `Forma de contato será excluido`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `srv-sistema/api/v1/pet-primavera/titular/contato-deletar/${id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });

            window.location.reload();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    excluirParcela(id) {
      Swal.fire({
        title: `Parcela será excluido`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `srv-sistema/api/v1/pet-primavera/parcelas/deletar/${id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });

            this.loadParcelas();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    salvarDependente() {
      this.carregandoSave = true;

      this.dependente.titular_id = this.titular.id;

      let url = this.dependente.id
        ? `srv-sistema/api/v1/pet-primavera/dependente/alterar/${this.dependente.id}`
        : "srv-sistema/api/v1/pet-primavera/dependente/salvar";

      if (this.data_obito != null) {
        this.dependente.data_obito = this.data_obito;
      }

      axios
        .post(url, this.dependente)
        .then((res) => {
          this.$toast(res.data.message, {
            type: "success",
          });

          this.loadModal();
          this.loadParcelas();
          this.loadDependentes();

          this.carregandoSave = false;
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    salvarBoletoGerar() {
      this.carregamentoGerarBoleto = true;

      axios
        .post(`${global}/srv-cobranca/gerar`, this.novoBoleto)
        .then((res) => {
          this.$toast(res.data.message, {
            type: "success",
          });

          this.carregamentoGerarBoleto = false;

          this.clearModalGerarBoleto();
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.errorNovoBoleto = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });

            this.carregamentoGerarBoleto = false;

            return;
          }

          this.$toast(res.response.data.message, {
            type: "error",
          });

          this.carregamentoGerarBoleto = false;
        });
    },
    gerarParcelasPost() {
      this.carregandoSave = true;
      this.gerarParcelas.plano_id = this.titular.plano_id;
      this.gerarParcelas.titular_id = this.titular.id;

      axios
        .post(
          "srv-sistema/api/v1/pet-primavera/parcelas/gerar",
          this.gerarParcelas
        )
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });
            this.loadParcelas();
            this.clearGerarParcela();
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
          }

          this.carregandoSave = false;
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.errorGerarParcelas = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }

          this.carregandoSave = false;
        });
    },
    clearObservacao() {
      this.observacao = {};
      this.errorObservacao = {};
      this.dialgoSaveObservacao = !this.dialgoSaveObservacao;

      if (this.dialgoSaveObservacao) this.loadCategorias();
    },
    clearParcela() {
      this.parcela = {
        valor_parcela: 0,
        valor_adesao: 0,
        valor_apagar: 0,
      };

      this.errorParcela = {
        valor_parcela: 0,
        valor_adesao: 0,
        valor_apagar: 0,
      };

      this.dialogParcela = !this.dialogParcela;
    },
    clearParcelaBaixa() {
      this.dialogEstornarParcela = !this.dialogEstornarParcela;
    },
    clearBaixa(modal = true) {
      this.baixa = {};

      this.errorBaixa = {};

      if (modal) {
        this.dialogBaixarParcela = !this.dialogBaixarParcela;
      } else {
        this.dialogPagamentoUnica = !this.dialogPagamentoUnica;
      }

      this.parcelasSelecionadas = [];
      this.quantidadeParcelaBaixa = 0;
    },
    clearBaixaUnica() {
      this.baixa = {
        valor_pago: 0,
        titular_id: null,
      };

      this.errorBaixa = {
        valor_pago: 0,
        titular_id: null,
      };

      this.dialogPagamentoUnica = !this.dialogPagamentoUnica;
      //this.clearBaixa();
      this.parcelasSelecionadas = [];
      this.quantidadeParcelaBaixa = 0;
    },
    clearGerarParcela() {
      this.gerarParcelas = {
        quantidade_parcela: null,
        plano_id: null,
        titular_id: null,
      };

      this.errorGerarParcelas = {
        quantidade_parcela: null,
        plano_id: null,
        titular_id: null,
      };

      this.dialogGerarParcela = !this.dialogGerarParcela;
    },
    clearDependentes() {
      this.dependente = {
        raca_id: null,
        titular_id: null,
        nome_pet: null,
        peso: null,
        porte: null,
        data_obito: null,
        is_resgate: false,
      };

      this.error = {
        raca_id: null,
        titular_id: null,
        nome_pet: null,
        peso: null,
        data_obito: null,
        porte: null,
        is_resgate: false,
      };

      this.dialogPet = !this.dialogPet;
    },
    criarNovoCampo() {
      this.titular.contatos.push({
        tipo: "",
        contato: "",
      });
    },
    removerCampo(index) {
      this.titular.contatos.splice(index, 1);
    },
    mudarLetra(value) {
      if (!value) return "";
      return value.toUpperCase();
    },
    setup() {
      this.$store.dispatch("verifyToken");
    },
    async printCard() {
      return await this.$htmlToPaper("print", {
        name: "_blank",
        specs: [],
        styles: ["css/cartao.css"],
        timeout: 1000,
        autoClose: true,
        windowTitle: window.document.title,
      });
    },
    async abrirContratoRouteQuery() {
      if (this.$route && this.$route.query.titular) {
        const titular = await axios.get(
          `srv-sistema/api/v1/pet-primavera/contrato/${this.$route.query.titular}`
        );

        if (titular.data && titular.data.status) {
          return this.modalDetalharCarregar(titular.data.titular);
        }
      }

      this.$toast("Não foi possivel acessar página!", {
        type: "error",
      });

      this.$router.push({ path: "/titulares-contratos" });
    },
    fecharModal() {
      this.dialogDetalhar = false;

      if (this.$route && this.$route.query.titular) {
        this.$router.go(-1);
      }
    },
    clearModalGerarBoleto() {
      this.dialogGerarBoleto = !this.dialogGerarBoleto;

      this.novoBoleto = {
        contrato: this.titular.contrato,
        forma_pagamento: "Boleto Avulso",
        data_vencimento: null,
        qtd_parcelas: 1,
        cancelar_anteriores: true,
      };

      this.errorNovoBoleto = {
        contrato: 0,
        forma_pagamento: null,
        data_vencimento: null,
        qtd_parcelas: null,
        cancelar_anteriores: null,
      };
    },
  },
  computed: {
    groupedAnexos() {
      return this.nomeTermos.reduce((groups, item) => {
        item.tipo = decodeURIComponent(item.tipo);
        const { tipo } = item;
        if (!groups[tipo]) groups[tipo] = [];
        groups[tipo].push(item);
        return groups;
      }, {});
    },
  },
  mounted() {
    this.setup();
    this.abrirContratoRouteQuery();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>

<style scoped>
.space {
  margin: 14px 5px;
}

.v-chip-size {
  max-width: 150px;
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.informacoes {
  margin-bottom: 2rem;
}

fieldset {
  border: 1px solid lightgray;
}

.itemsContainerContact {
  font-size: 0.8rem;
  font-weight: 400;
  width: 45%;
}

.imagem-pet img {
  width: 40px;
  cursor: pointer;
}

.info-parcela {
  margin-top: -20px;
  margin-bottom: 1rem;
}

.carencia {
  background: rgba(248, 248, 117, 0.904);
}

.deletado {
  background: rgba(212, 97, 89, 0.904);
}

.obito {
  background: rgba(211, 157, 7, 0.904);
}

.divide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartao-pet {
  padding: 20px 20px;
}

.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}

.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}

.cartao-pet img {
  width: 100%;
  height: 100%;
}

.imagem-pet-icon-horizontal img {
  width: 20px;
}

.imagem-pet-icon img {
  width: 35px;
}

.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}

.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}

.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}

.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}

.cartao-pet {
  padding: 20px 20px;
}

.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}

.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}

.cartao-pet img {
  width: 100%;
  height: 100%;
}

.imagem-pet-icon-horizontal img {
  width: 20px;
}

.imagem-pet-icon img {
  width: 35px;
}

.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}

.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}

.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}

.texto_cartao_cpf {
  position: absolute;
  margin-top: -45px;
}

.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}

.margin-negativa {
  margin-top: -30px;
  margin-bottom: 30px;
}

.estornarObito {
  background: orange;
}

#extrato {
  padding: 20px 20px;
}

#extrato img {
  width: 160px;
}

#extrato h1 {
  font-size: 18px;
  text-align: right;
}

#extrato .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#extrato ul {
  list-style: none;
}

#extrato ul li {
  text-align: right;
}

.v-list-item__title {
  white-space: pre-line;
}

.primary-text {
  color: #007744;
  list-style: none;
  padding: 10px 10px;
  font-weight: bold;
}

.copiar_codigo_pix {
  padding: 20px 20px;
  background: #ececec;
  text-align: center;
}

.baixado {
  background: #007744;
  color: #ddd;
}

.cancelado {
  background: rgb(209, 67, 67);
  color: #ddd;
}

.total {
  width: 100%;
}

.card-titular-contrato {
  padding: 15px;
  width: 350px;
}

.title-card-termos {
  font-size: 1rem;
}

.link-termo {
  margin: 15px;
}

.link-termo:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #007744;
}

.term-type {
  white-space: normal;
  /* Permite quebra de linha normal */
  word-wrap: break-word;
  /* Quebra a linha entre as palavras */
  display: inline-block;
  /* Garante que as propriedades funcionem corretamente */
  max-width: 100%;
  /* Ajusta para o tamanho disponível */
}

.v-chip-size-titular-contrato {
  width: 100%;
}

.buttom-send {
  margin-left: 5px;
  margin-top: 2px;
}

.scrolling-cell {
  overflow: hidden;
}

.inner-cell {
  white-space: nowrap;
  animation: scrollText 7s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-110%);
  }
}
</style>
