
<template>
  <div>
    <div>
      <v-card flat>
        <v-card-title>
          <b class="titulo-header-page">Relatório - Clientes em Atrasos</b>
          <v-spacer></v-spacer>
        </v-card-title>

        <div class="ml-5 mr-5">
          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field
                class="input"
                color="#007744"
                label="Data inicial:"
                v-model="params.inicio"
                required
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                class="input"
                v-model="params.fim"
                color="#007744"
                type="date"
                label="Data final:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="align-center">
              <v-btn
                color="#007744"
                class="white--text"
                depressed
                @click="this.loadRelatorio"
              >
                <v-icon left>mdi-magnify</v-icon>
                Pesquisar
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <loading v-if="carregamento" />

        <found v-else-if="!carregamento && relatorio.length == 0" />

        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-center">Contrato</th>
                <th class="text-left">Plano</th>
                <th class="text-left">Titular</th>
                <th class="text-center">Quantidade de Parcela</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in relatorio" :key="index">
                <td class="text-center">{{ item.id }}</td>
                <td class="text-center">{{ item.contrato }}</td>
                <td class="text-left">{{ item.descricao }}</td>
                <td class="text-left">
                  <a
                    class="link"
                    @click="redirecionar(`/contrato?titular=${item.id}`)"
                    >{{ item.nome }}</a
                  >
                </td>
                <td class="text-center">
                  <v-chip label small color="#007744" class="white--text">
                    {{ item.qtd_parcelas_atrasos }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from "../../axios/service_private.js";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      carregamento: false,
      relatorio: [],
      usuarios: [],
      params: {
        inicio: moment().startOf("month").format("yyyy-MM-DD"),
        fim: moment().format("yyyy-MM-DD"),
      },
    };
  },
  methods: {
    redirecionar(route) {
      this.$router.push({ path: route });
    },
    loadUsuarios() {
      axios.get("srv-sistema/api/v1/pet-primavera/usuarios-all").then((response) => {
        if (response.data.status) {
          this.usuarios = response.data.usuarios;
        }
      });
    },
    async loadRelatorio() {
      this.relatorio = [];
      this.carregamento = true;

      const relatorio = await axios.get(
        "srv-sistema/api/v1/pet-primavera/relatorio/clientes-atr",
        {
          params: this.params,
        }
      );

      if (relatorio.data.status) {
        this.relatorio = relatorio.data.relatorio;
        this.carregamento = false;
      }
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadRelatorio();
      this.loadUsuarios();
    },
  },
  mounted() {
    this.setup();
  },
};
</script>

<style scoped>
.link {
  color: #007744;
}
</style>