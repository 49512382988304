<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <b class="titulo-header-page">Relatório - Cremação e Associaos Pax(Pet)</b>
      </v-card-title>
      <div class="ml-5 mr-5 d-flex">
        <v-row dense>
          <v-col cols="12" md="3">
            <v-autocomplete item-value="value" item-text="nome" label="Tipo Relatório:" required color="#007744"
              v-model="relatorioSelecionado" :items="selecionarRelatorio" @input="limpar" auto-select-first outlined
              :error-messages="error.relatorio" dense chips clearable deletable-chips></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field class="input" color="#007744" label="Data inicial:" v-model="params.inicio" required
              type="date" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field class="input" v-model="params.fim" color="#007744" type="date" label="Data final:" required
              outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete item-value="id" item-text="nome" label="Cidade:" color="#007744" v-model="cidade" required
              :items="cidades" v-if="liberarCidadePreencher" :error-messages="error.cidade_id" auto-select-first
              outlined dense chips clearable deletable-chips></v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div class="ml-5 mr-5 d-flex">
        <v-btn small color="#007744" class="mr-4 white--text" @click="gerarRelatorio()">
          <v-icon dark> mdi-plus </v-icon>
          Gerar
        </v-btn>
        <v-btn small color="#007744" class="mr-4 mb-5 white--text" @click="gerarRelatorioExcel(relatorioSelecionado)"
          :disabled="!carregamento && relatorio.length === 0">
          <v-icon dark> mdi-microsoft-excel </v-icon>
        </v-btn>
      </div>

      <loading v-if="carregamento" />

      <found v-else-if="!carregamento && relatorio.length == 0" />

      <div v-else-if="relatorioGerado" class="mt-5">
        <div v-if="relatorioSelecionado === 'adicionaisTotal'">
          <v-row dense>
            <v-col cols="12">
              <widget icon="mdi-paw" class="centralizar-informacoes" subTitle="Total de Adicionais PET"
                color="blue darken-4" :title="String(relatorio[0].TOTAL_ADICIONAIS_PET)" />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-simple-table dense class="mt-2 ml-5 mr-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="header in retornarArrayPropriedadesObjeto(relatorio)" :key="header.key"
                    :class="header.align">
                    {{ header.td }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, rowIndex) in relatorio" :key="rowIndex">
                  <td v-for="(
header, colIndex
                    ) in retornarArrayPropriedadesObjeto(relatorio)" :key="colIndex" :class="header.align">
                    {{
                      header && header.is_format_real
                        ? formatValor(item[header.chave])
                        : item[header.chave]
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-for="header in retornarArrayPropriedadesObjeto(
                  [valores],
                  true
                )" :key="header">
                  <th colspan="12">
                    TOTAL: <b>{{ formatValor(valores[header]) }}</b>
                  </th>
                </tr>
                <tr>
                  <th colspan="12">
                    {{ textTotalRegistro }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </div>
      </div>
      <found v-else />
    </v-card>
  </div>
</template>

<script>
var moment = require("moment");
moment.locale("pt-br");

import ExcelJS from "exceljs";
import axios from "../../axios/service_private.js";

export default {
  data() {
    return {
      carregamento: false,
      cidade: null,
      liberarCidadePreencher: false,
      cidades: [],
      selecionarRelatorio: [
        { nome: "Cremação", value: "os" },
        { nome: "Óbitos", value: "obitos" },
        { nome: "Adicionais", value: "adicionais" },
        { nome: "Quantidade Pets", value: "adicionaisTotal" },
      ],
      relatorioSelecionado: null,
      relatorio: [],
      params: {
        inicio: null,
        fim: null,
      },
      relatorioGerado: null,
      textTotalRegistro: "",
      valores: null,
      error: {},
    };
  },
  methods: {
    retornarArrayPropriedadesObjeto(resultado, keys = false) {
      if (resultado.length > 0) {
        let propriedades = Object.keys(resultado[0]);

        if (keys) return propriedades;

        propriedades.map((header, index) => {
          let align = this.tableCondicao(
            ["VALOR", "DATA", "NUMERO", "CONTRATO"],
            header,
            "text-center",
            "text-left"
          );
          let is_format_real = this.tableCondicao(
            ["VALOR", "TOTAL"],
            header,
            true,
            false
          );
          let td = header.replace("_", " ").toUpperCase();

          propriedades[index] = { chave: header, align, is_format_real, td };
        });
        return propriedades;
      }
      return [];
    },
    tableCondicao(fields, field, condition_1, condition_2) {
      return fields.some((e) => field.toUpperCase().startsWith(e))
        ? condition_1
        : condition_2;
    },
    liberarCidade() {
      this.liberarCidadePreencher = !(
        this.relatorioSelecionado === "os" || this.relatorioSelecionado === null
      );
    },
    async loadConexoes() {
      const conexoes = await axios.get(
        "srv-sistema/api/v1/pet-primavera/relatorio/conexoes"
      );

      if (conexoes.data.status) {
        this.cidades = conexoes.data.conexoes;
      }
    },
    limpar() {
      this.relatorioGerado = false;
      this.cidade = null;
      this.relatorio = [];
      this.valores = null;
      this.textTotalRegistro = null;
      this.error = {};
    },
    async gerarRelatorio() {
      this.relatorioGerado = true;
      this.carregamento = true;
      this.error = {};

      let params = {
        relatorio: this.relatorioSelecionado,
        data_inicial: this.params.inicio,
        data_fim: this.params.fim,
      };

      if (
        ["adicionais", "obitos", "adicionaisTotal"].includes(
          this.relatorioSelecionado
        )
      ) {
        params["cidade_id"] = this.cidade;
      }

      try {
        const relatorioSelecionado = await axios.get(
          "srv-sistema/api/v1/pet-primavera/relatorio/relatorios-mensais",
          { params }
        );

        if (relatorioSelecionado.data.status) {
          this.relatorio = relatorioSelecionado.data.relatorio.registros;
          this.valores = relatorioSelecionado.data.relatorio.totais ?? {};
          this.textTotalRegistro = `TOTAL DE REGISTROS DO RELATÓRIO ${this.tipoRelatorio()}: (${relatorioSelecionado.data.relatorio.items
            }).`;
        }
      } catch (res) {
        if (res.response.data && res.response.data.message.params) {
          this.$toast(res.response.data.message.message, {
            type: "error",
          });

          this.error = res.response.data.message.params;
        } else {
          this.$toast(res.toString(), {
            type: "error",
          });
        }
      }
      this.carregamento = false;
    },
    async gerarRelatorioExcel(tipo) {
      try {
        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet(`Relatório - Pet Particular`);

        const columns = {
          adicionais: [
            { header: "CONTRATO", key: "contrato", width: 20 },
            { header: "NOME", key: "nome", width: 20 },
            { header: "PORTE", key: "porte", width: 20 },
            { header: "VALOR DE ADESÃO", key: "valorAdesao", width: 10 },
            { header: "VALOR MENSAL", key: "valorMensal", width: 10 },
            { header: "DATA DO CONTRATO", key: "dataContrato", width: 20 },
          ],
          obitos: [
            { header: "CONTRATO", key: "contrato", width: 20 },
            { header: "NOME DO CLIENTE", key: "nomeCliente", width: 20 },
            { header: "NOME DO DEPENDENTE", key: "nomeDependente", width: 20 },
            {
              header: "DATA DE FALECIMENTO",
              key: "dataFalecimento",
              width: 20,
            },
          ],
          os: [
            { header: "OS", key: "numeroOrdemServico", width: 10 },
            { header: "ATENDENTE", key: "atendente", width: 20 },
            { header: "CONTRATANTE", key: "nomeContratante", width: 20 },
            { header: "PAGAMENTO", key: "formaPagamento", width: 20 },
            { header: "CPF", key: "cpfContratante", width: 20 },
            { header: "CIDADE", key: "cidadeContratante", width: 20 },
            { header: "PET", key: "pet", width: 20 },
            { header: "VALOR DO KIT", key: "valorKit", width: 10 },
            { header: "VALOR TOTAL", key: "valorTotal", width: 10 },
          ],
        };

        const dataMapping = {
          adicionais: (item) => ({
            contrato: item.CONTRATO,
            nome: item.NOME,
            porte: item.PORTE,
            valorAdesao: item.VALOR_ADESAO,
            valorMensal: item.VALOR_MENSAL,
            dataContrato: item.DATA_CONTRATO,
          }),
          obitos: (item) => ({
            contrato: item.CONTRATO,
            nomeCliente: item.NOME_CLIENTE,
            nomeDependente: item.NOME_DEPENDENTE,
            dataFalecimento: item.DATA_FALECIMENTO,
          }),
          os: (item) => ({
            numeroOrdemServico: item.numeroOrdemServico,
            atendente: item.atendente,
            nomeContratante: item.nomeContratante,
            formaPagamento: item.formaPagamento,
            cpfContratante: item.cpfContratante,
            cidadeContratante: item.cidadeContratante,
            pet: item.PET,
            valorKit: item.valorKit,
            valorTotal: item.valorTotal,
          }),
        };

        worksheet.columns = columns[tipo];

        this.relatorio.forEach((item) =>
          worksheet.addRow(dataMapping[tipo](item))
        );

        const buffer = await workbook.xlsx.writeBuffer();

        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute(
          "download",
          `Relatório - ${this.tipoRelatorio()} - Pet Particular`
        );

        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        this.$toast(error, { type: "error" });
      }
    },
    tipoRelatorio() {
      switch (this.relatorioSelecionado) {
        case "os":
          return "CREMADOS";
        case "obitos":
          return "ÓBTIDOS";
        case "adicionais":
          return "ADICIONAIS";
        case "quantidadePets":
          return "QUANTIDADES DE ADICIONAIS PETS NA FILIAL";
        default:
          break;
      }
    },
    getCurrentDateFormatted() {
      this.params.inicio = moment().startOf("month").format("YYYY-MM-DD");
      this.params.fim = moment().endOf("month").format("YYYY-MM-DD");
    },
    formatValor(number) {
      return number.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadConexoes();
      this.getCurrentDateFormatted();
    },
  },
  watch: {
    relatorioSelecionado() {
      this.liberarCidade();
    },
  },
  mounted() {
    this.setup();
  },
};
</script>
