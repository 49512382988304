<template>
  <div>
    <div>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title><b>Postar</b></v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" class="pa-8 mx-auto">
            <v-row dense>

              <v-col cols="12" v-if="blog && !blog.ID">
                <v-file-input label="Foto postagem:" color="#007744" v-model="blog.file" accept=".jpg,.png,.pdf"
                  outlined dense :error-messages="error.file">
                </v-file-input>
              </v-col>

              <v-col cols="12">
                <v-text-field label="Titulo:" color="#007744" v-model="blog.titulo" outlined dense required
                  :error-messages="error.Titulo">
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea label="Descrição:" color="#007744" v-model="blog.descricao" outlined dense rows="3" required
                  :error-messages="error.Descricao">
                </v-textarea>
              </v-col>
            </v-row>

            <v-btn small color="#007744" class="mr-4 mt-3 white--text" @click="salvar" :disabled="carregamentoSave"
              :loading="carregamentoSave">
              <v-icon dark>mdi-check</v-icon>
              Salvar
            </v-btn>

            <v-btn small color="error" class="mr-4 mt-3" @click="clear">
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
          </v-form>
        </v-card>
      </v-dialog>

      <loading v-if="carregamento" />

      <v-card v-else flat class=" elevation-0">
        <v-card-title>
          <b class="titulo-header-page">Blog - Postagens</b>
          <v-spacer></v-spacer>
          <v-btn small color="#007744" class="white--text" @click="clear">
            <v-icon dark> mdi-plus </v-icon>
            Nova Postagem
          </v-btn>
        </v-card-title>

        <sistema message="Nenhuma publicação encontrada!" v-if="postagens && postagens.length <= 0" />

        <v-container fluid v-else>
          <v-row dense>
            <v-col v-for="(item, index) in postagens" :key="index" cols="12" sm="6" md="4" lg="3">
              <v-card class=" elevation-0" outlined>
                <v-img class="white--text align-end" height="200px" :src="global + 'srv-site/' + item.path_imagem" />
                <v-card-subtitle class="pb-0">
                  <strong>{{ item.titulo }}</strong>
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>Postagem: <strong>{{ dateFormat(item.CreatedAt) }}</strong> por <strong>{{ item.usuario.nome
                      }}</strong></div>
                </v-card-text>

                <v-card-actions>
                  <v-btn icon @click="item.show = !item.show">
                    <v-icon>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>

                  <v-btn icon color="#007744" @click="editar(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn icon @click="excluir(item.ID)">
                    <v-icon color="red lighten-2">mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="item.show">
                    <v-divider></v-divider>
                    <v-card-text>
                      <div>{{ item.descricao }}</div>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>

            </v-col>
          </v-row>
        </v-container>

      </v-card>
      <div class="text-left pa-5">
        <v-pagination v-model="pagination.page" :length="pagination.lastPage" :total-visible="pagination.perPage"
          color="#007744" v-if="postagens.length > 0" @input="(page) => loadPostagens(page)"
          @next="() => loadPostagens(pagination.page)" @previous="() => loadPostagens(pagination.page)"></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../../axios/service_private";
import Swal from "sweetalert2";
import global from '../../url.api.js';

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      dialog: false,
      global: global,
      carregamento: false,
      carregamentoSave: false,
      postagens: [],
      blog: {
        file: null,
        usuario_id: null,
        descricao: null,
        titulo: null
      },
      error: {},
      pagination: {
        total_records: 1,
        total_pages: 1,
        lastPage: 1,
        page: 1
      },
    };
  },
  methods: {
    excluir(id) {
      Swal.fire({
        title: `Postagem será excluida`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `srv-site/api/blog/${id}`
          );

          Swal.fire({
            title: "Exclusão realizada!",
            text: res.data.message,
            icon: "success",
            confirmButtonColor: "#007744",
          });

          this.setup();
        }
      });
    },
    editar(item) {
      this.dialog = !this.dialog;

      this.blog = item;
    },
    clear() {
      this.blog = {
        file: null,
        usuario_id: null,
        descricao: null,
        titulo: null
      };

      this.error = {};

      this.dialog = !this.dialog;
    },
    async salvar() {
      if (this.blog && this.blog.ID) {
        return this.salvarAlteracao();
      }

      try {
        this.carregandoSave = true;

        const usuario = JSON.parse(localStorage.getItem("user"));

        const formData = new FormData();

        formData.append('usuario_id', usuario.id);

        Object.entries(this.blog).forEach(([key, value]) => {
          if (value != null) {
            formData.append(key, value);
          }
        });

        const res = await axios.post(`srv-site/api/blog`, formData, { headers: { "Content-Type": "multipart/form-data" } });

        this.$toast(res.data.message, {
          type: "success",
        });

        this.clear();

        this.setup();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          this.error = error.response.data.errors;

          this.$toast(error.response.data.message, {
            type: "error",
          });
        } else {
          this.$toast(error.response.data.message, {
            type: "error",
          });
        }
      } finally {
        this.carregandoSave = false;
      }
    },
    async salvarAlteracao() {
      this.carregandoSave = true;

      try {
        const res = await axios.put(`srv-site/api/blog`, this.blog);

        this.$toast(res.data.message, {
          type: "success",
        });

        this.clear();
        this.setup();

      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          this.error = error.response.data.errors;

          this.$toast(error.response.data.message, {
            type: "error",
          });
        } else {
          this.$toast(error.response.data.message, {
            type: "error",
          });
        }
      } finally {
        this.carregandoSave = false;
      }
    },
    dateFormat(param, format = "DD/MM/YYYY") {
      return moment(param).format(format);
    },
    async loadPostagens(params) {
      this.carregamento = true;

      try {
        const response = await axios.get("srv-site/api/blog-postagens", {
          params: {
            page: params,
          },
        });

        this.postagens = response.data.items;

        this.pagination = {
          page: response.data.page,
          perPage: response.data.last_page,
          lastPage: response.data.total_pages,
          total: response.data.total_records,
        };

        this.carregamento = false;
      } catch (error) {
        console.log(error)
      }
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadPostagens(this.pagination.page);
    },
  },
  mounted() {
    this.setup();
  },
};
</script>