
<template>
  <div>
    <div>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title><b>Nova raça</b> </v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" class="pa-8 mx-auto">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  color="#007744"
                  v-model="raca.nome"
                  :error-messages="error.nome"
                  label="Nome:"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col>
                <v-autocomplete
                  class="mb-5"
                  item-value="id"
                  item-text="nome"
                  label="Espécie:"
                  color="#007744"
                  :items="especies"
                  v-model="raca.especie_id"
                  auto-select-first
                  outlined
                  dense
                  chips
                  clearable
                  deletable-chips
                  :error-messages="error.especie_id"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-btn
              small
              color="#007744"
              class="mr-4 mt-3 white--text"
              @click="salvar"
              :disabled="carregamentoSave"
              :loading="carregamentoSave"
            >
              <v-icon dark> mdi-check </v-icon>
              Salvar
            </v-btn>

            <v-btn small color="error" class="mr-4 mt-3" @click="clear">
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </v-form>
        </v-card>
      </v-dialog>

      <loading v-if="carregamento" />

      <v-card v-else flat>
        <v-card-title>
          <b class="titulo-header-page">Raças Animais</b>
          <v-spacer></v-spacer>
          <v-btn small color="#007744" class="white--text" @click="clear">
            <v-icon dark> mdi-plus </v-icon>
            Nova Raça
          </v-btn>
        </v-card-title>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-center">Espécie</th>
                <th class="text-center">Data criação</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in racas" :key="index">
                <td class="text-left">{{ item.id }}</td>
                <td class="text-left">{{ item.nome }}</td>
                <td class="text-center">
                  {{ item._especie_nome }}
                </td>
                <td class="text-center">
                  {{ dateFormat(item.created_at) }}
                </td>
                <td class="text-left">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="black"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="editar(item)">
                        <v-list-item-title
                          >Alterar informações</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="excluir(item.id)">
                        <v-list-item-title>Excluir raça</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <div class="text-left pa-5">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.lastPage"
            :total-visible="pagination.perPage"
            color="#007744"
            v-if="racas.length > 0"
            @input="(page) => loadRacas(page)"
            @next="() => loadRacas(pagination.page)"
            @previous="() => loadRacas(pagination.page)"
          ></v-pagination>
        </div>
    </div>
  </div>
</template>
<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      dialog: false,
      carregamento: false,
      carregamentoSave: false,
      racas: [],
      raca: {
        nome: null,
        especie_id: null,
      },
      error: {
        nome: null,
        especie_id: null,
      },
      especies: [],
      pagination: {
        page: 1,
        perPage: 1,
        lastPage: 1,
      },
    };
  },
  methods: {
    excluir(id) {
      Swal.fire({
        title: `Raça será excluida`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `srv-sistema/api/v1/pet-primavera/raca/deletar/${id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });
            this.setup();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    editar(item) {
      this.dialog = !this.dialog;
      this.raca = item;
    },
    clear() {
      this.raca = {
        nome: null,
        especie_id: null,
      };

      this.error = {
        nome: null,
        uf: null,
      };

      this.dialog = !this.dialog;
    },
    loadEspecies() {
      axios
        .get("srv-sistema/api/v1/pet-primavera/especies-sem-pagination")
        .then((response) => {
          if (response.data.status) {
            this.especies = response.data.especies;
          }
        });
    },
    salvar() {
      this.carregandoSave = true;

      let url = this.raca.id
        ? `srv-sistema/api/v1/pet-primavera/raca/alterar/${this.raca.id}`
        : "srv-sistema/api/v1/pet-primavera/raca/salvar";

      axios
        .post(url, this.raca)
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.clear();
            this.setup();

            this.carregandoSave = false;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
            this.carregandoSave = false;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY HH:mm");
    },
    async loadRacas(params) {
      this.carregamento = true;

      const racas = await axios.get("srv-sistema/api/v1/pet-primavera/racas", {
        params: {
          page: params,
        },
      });

      if (racas.data.status) {
        this.racas = racas.data.racas.data;

        this.pagination = {
          page: racas.data.racas.page,
          perPage: racas.data.racas.perPage,
          lastPage: racas.data.racas.lastPage,
          total: racas.data.racas.total,
        };

        this.carregamento = false;
      }
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadRacas(this.pagination.page);
      this.loadEspecies();
    },
  },
  mounted() {
    this.setup();
  },
};
</script>